<template>
  <Page :title="$t('info.title')" :classes="['page--static', 'page--info']">
    <div v-html="$t('info.content')"></div>
  </Page>
</template>

<script>
export default {
  name: 'InfoPage',
};
</script>
